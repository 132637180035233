import "../General.css";
import logo from "../assets/turtle pools and spa logo.png";
import hero from "../assets/hero turtle pools and spa.png";
import wave from "../assets/divider.svg";

import flagstone from "../assets/flagstone.png";
import bema from "../assets/logo-bema.png";
import nobletile from "../assets/noble-tile.png";
import pebbletec from "../assets/pebbletec-logo.png";
import tremstone from "../assets/tremstone.png";
import build from "../assets/build.jpeg";

import repair from "../assets/repair.jpg";
import remodel from "../assets/remodel.jpg";

const Home = () => {
  return (
    <div>
      <header className="header">
        <div>
          <img style={{ height: "100px" }} src={logo} alt="logo" />
        </div>
        <div>
          <nav>
            <ul
              style={{
                display: "flex",
                gap: "50px",
                listStyle: "none",
                justifyContent: "space-between",
                alignItems: "center",
                cursor: "pointer",
                color: "white",
                fontSize: "px",
              }}
            >
              <li>Home</li>
              <li>Services</li>
              <li>Suppliers</li>
              <button
                className="li-button"
                style={{
                  backgroundColor: "white",
                  color: "black",
                  padding: "10px",
                  borderRadius: "10px",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  fontSize: "18px",
                  border: "none",
                  boxShadow:
                    "0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                }}
                onClick={() => {
                  window.location.href = "tel:7274826677";
                }}
              >
                {" "}
                <i className="material-icons">call</i>
                (727) 482 6677
              </button>
            </ul>
          </nav>
        </div>
      </header>

      <main>
        <div className="hero">
          <h1
            style={{
              fontSize: "50px",

              textShadow:
                "0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            }}
          >
            Turtle Pools And Spa
          </h1>
          <hr style={{ width: "40vw" }}></hr>
          <h1>We Are Here To Build Your Dream Pool</h1>
          <button onClick={() => (window.location.href = "tel:7274826677")}>
            Contact Us
          </button>
          <div className="wave" style={{ backgroundImage: `url(${wave})` }}>
            {" "}
          </div>
        </div>

        <div className="main-content">
          <strong>
            With More Than 40 Years Of Experience, Turtle Pools And Spa Is Your
            Best Option In St. Petersburg, Florida
          </strong>

          <p>
            You name it, we can do it. We can work on projects of almost any
            scale and we will strive to work with you to meet your budget. Our
            expertise goes beyond pools to include tiling, pavers, and a range
            of other abilities to improve the outdoor entertainment area of your
            home. See our list of services below. Don’t be shy, get in touch
            with us and find out what we can do for you!
          </p>

          <strong>Our Services</strong>
          <p style={{ marginTop: "-30px" }}>
            We can remodel, repair, or build your pool according to your needs
          </p>

          <div className="services">
            <div
              style={{
                display: "flex",
                gap: "20px",
                flexDirection: "column",
                backgroundColor: "white",
                borderRadius: "10px",
                boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
                padding: "7px",

                width: "300px",
                height: "auto",
                alignItems: "center",
              }}
            >
              <img
                style={{
                  height: "150px",
                  width: "100%",
                  objectFit: "cover",
                  borderRadius: "10px",
                }}
                src={build}
                alt="build"
              />
              <span
                style={{
                  color: "#04133A",
                  fontWeight: "600",
                  fontSize: "20px",
                }}
              >
                Build
              </span>
            </div>

            <div
              style={{
                display: "flex",
                gap: "20px",
                flexDirection: "column",
                backgroundColor: "white",
                borderRadius: "10px",
                boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
                padding: "7px",

                width: "300px",
                height: "auto",
                alignItems: "center",
              }}
            >
              <img
                style={{
                  height: "150px",
                  width: "100%",
                  objectFit: "cover",
                  borderRadius: "10px",
                }}
                src={repair}
                alt="build"
              />
              <span
                style={{
                  color: "#04133A",
                  fontWeight: "600",
                  fontSize: "20px",
                }}
              >
                Repair
              </span>
            </div>

            <div
              style={{
                display: "flex",
                gap: "20px",
                flexDirection: "column",
                backgroundColor: "white",
                borderRadius: "10px",
                boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
                padding: "7px",

                width: "300px",
                height: "auto",
                alignItems: "center",
              }}
            >
              <img
                style={{
                  height: "150px",
                  width: "100%",
                  objectFit: "cover",
                  borderRadius: "10px",
                }}
                src={remodel}
                alt="build"
              />
              <span
                style={{
                  color: "#04133A",
                  fontWeight: "600",
                  fontSize: "20px",
                }}
              >
                Remodel
              </span>
            </div>
          </div>

          <strong>Let Us Bring Your Dreams To Life</strong>

          <p>
            At Turtle Pool and Spa we pride ourselves on the highest quality of
            work, whether you’re looking for a small resurfacing job or a full
            scale commercial pool. With over 40 years of experience in the
            business, you can rest assured that our final product will exceed
            your expectations
          </p>

          <strong>
            We Provide Everything You Need For A Stress Free Project
          </strong>

          <div className="icons">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                alignItems: "center",
              }}
            >
              <i
                style={{ color: "#04133A", fontSize: "90px" }}
                className="material-icons"
              >
                savings
              </i>
              <h3>Fixed Budget</h3>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                alignItems: "center",
              }}
            >
              <i
                style={{ color: "#04133A", fontSize: "90px" }}
                className="material-icons"
              >
                handyman
              </i>
              <h3>Project Management</h3>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                alignItems: "center",
              }}
            >
              <i
                style={{ color: "#04133A", fontSize: "90px" }}
                className="material-icons"
              >
                pending_actions
              </i>
              <h3>Firm Deadlines</h3>
            </div>
          </div>

          <strong>Call Us And Get A Free Quote</strong>

          <button
            style={{
              backgroundColor: "#04133A",
              color: "white",
              padding: "10px",
              borderRadius: "10px",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              gap: "10px",
              fontSize: "18px",
              border: "none",
              boxShadow:
                "0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            }}
            onClick={() => {
              window.location.href = "tel:7274826677";
            }}
          >
            {" "}
            <i className="material-icons">call</i>
            (727) 482 6677
          </button>
        </div>

        <h2
          style={{
            textAlign: "center",
            marginTop: "50px",
            color: "#04133A",
            fontWeight: "600",
          }}
        >
          Suppliers
        </h2>
        <hr style={{ width: "200px", margin: "auto" }}></hr>

        <div className="suppliers">
          <div>
            <img
              style={{ width: "200px", objectFit: "contain" }}
              src={pebbletec}
              alt="suppliers"
            />
          </div>
          <div>
            <img
              style={{ width: "200px", objectFit: "contain" }}
              src={bema}
              alt="suppliers"
            />
          </div>
          <div>
            <img
              style={{ width: "200px", objectFit: "cover" }}
              src={tremstone}
              alt="suppliers"
            />
          </div>
          <div>
            <img
              style={{ width: "200px", objectFit: "contain" }}
              src={nobletile}
              alt="suppliers"
            />
          </div>
          <div
            style={{
              width: "200px",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              padding: "10px",
              backgroundColor: "#002B5F",
            }}
          >
            {" "}
            <img
              style={{ width: "200px", objectFit: "contain" }}
              src={flagstone}
              alt="suppliers"
            />
          </div>
        </div>

        <div className="sticky-mobile">
          <button
            style={{
              width: "100%",
              display: "flex",
              gap: "10px",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#04133A",
              color: "white",
              padding: "10px",
              borderRadius: "10px",
              cursor: "pointer",
              fontSize: "18px",
              border: "none",

              boxShadow:
                "0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            }}
            onClick={() => {
              window.location.href = "tel:7274826677";
            }}
          >
            {" "}
            <i className="material-icons">call</i>
            Call us and get a free quote
          </button>
        </div>

        <footer>
          <div
            style={{
              display: "flex",
              justifyContent: "center",

              alignItems: "center",
              marginTop: "50px",
              backgroundColor: "#04133A",
              padding: "20px",
              flexDirection: "column",
            }}
          >
            <img
              style={{ width: "200px", objectFit: "contain" }}
              src={logo}
              alt="logo"
            />

            <p style={{ color: "white" }}>Turtle Pools and Spa LLC</p>

            <p style={{ color: "white", marginTop: "0" }}>
              © 2022 Turtle Pools.
            </p>
          </div>
        </footer>
      </main>
    </div>
  );
};

export default Home;
